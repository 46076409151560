<template>
	<div v-loading="loading" class="flex flex-col mt-3">
		<div class="flex bg-white p-3 mb-4">
			<div class="w-full">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label
								class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
								<input data-cy='apply-send_order_notifications_to_admin'
									   type="checkbox"
									   id="send_order_notifications_to_admin"
									   v-model="form.allow_seller_place_order"
									   class="checkbox-height  h-4 w-4">
								<span class="ml-3"> {{ $t('allow_seller_place_order') }}<span
									class="help-tooltip cursor-pointer"
									v-bind:title="$t('allow_seller_place_order')"></span></span>
							</label>
							<div class="form-group w-full mt-2" v-if="form.allow_seller_place_order">

								<div class="text-sm text-gray-600 w-full mb-2 border-bottom">
									<span>{{ $t('sellerAllowedToPlaceOrder') }}</span>
									<span class="float-right"><i class="lnr-chevron-down"
																 @click="openAllowSellerPlaceOrderToggle = true"
																 v-if="openAllowSellerPlaceOrderToggle === false"></i><i
										class="lnr-chevron-up" @click="openAllowSellerPlaceOrderToggle = false"
										v-else></i></span>
								</div>

								<div class="form-group unset-margin mt-2"
									 v-if="openAllowSellerPlaceOrderToggle === true">
									<div>
										<input v-model="searchSellerName" type="text"
											   @input="filterAllowSellerPlaceOrder"
											   @focus="openAllowSellerPlaceOrderDropDown = true"
											   :placeholder="$t('search')"
											   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
									<div class="absolute inset-0 z-40"
										 @click="openAllowSellerPlaceOrderDropDown = false"
										 v-if="openAllowSellerPlaceOrderDropDown"></div>
									<div style='z-index:99999'
										 class="w-1/2 absolute bg-white shadow border rounded mt-1"
										 v-if="openAllowSellerPlaceOrderDropDown && filteredSellerSellerPlaceOrderList.length > 0">
										<ul>
											<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredSellerSellerPlaceOrderList.slice(0, 7)"
												:key="item.id" @click="addSellerAllowedToPlaceOrder(item)"
											>{{ item.name }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row" v-if="openAllowSellerPlaceOrderToggle === true">
									<div class="col-md-6 mb-1 mt-2"
										 v-for="(seller,key) in form.seller_allowed_to_place_orders"
										 :key="key">
										<div class="flex justify-between bg-white border rounded-md shadow-sm p-1">
											<div>{{ seller.name }}</div>
											<div>
                                                <span class="cursor-pointer"
													  @click="removeSellerAllowedToPlaceOrder(key)"
												>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
														 class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														 viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
														  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														  clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label
								class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
								<input data-cy='apply-send_order_notifications_to_admin'
									   type="checkbox"
									   id="send_order_notifications_to_admin"
									   v-model="form.allow_seller_to_see_your_orders"
									   class="checkbox-height  h-4 w-4">
								<span class="ml-3"> {{ $t('allow_seller_to_see_your_orders') }}<span
									class="help-tooltip cursor-pointer"
									v-bind:title="$t('allow_seller_to_see_your_orders')"></span></span>
							</label>
							<div class="form-group w-full mt-2" v-if="form.allow_seller_to_see_your_orders">

								<div class="text-sm text-gray-600 w-full mb-2 border-bottom">
									<span>{{ $t('sellerAllowedToSeeOrder') }}</span>
									<span class="float-right"><i class="lnr-chevron-down"
																 @click="openAllowSellerSeeOrderToggle = true"
																 v-if="openAllowSellerSeeOrderToggle === false"></i><i
										class="lnr-chevron-up" @click="openAllowSellerSeeOrderToggle = false"
										v-else></i></span>
								</div>

								<div class="form-group unset-margin mt-2" v-if="openAllowSellerSeeOrderToggle === true">
									<div>
										<input v-model="searchShopName" type="text"
											   @input="filterAllowSeller"
											   @focus="openAllowSellerSeeOrderDropDown = true"
											   :placeholder="$t('search')"
											   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
									<div class="absolute inset-0 z-40" @click="openAllowSellerSeeOrderDropDown = false"
										 v-if="openAllowSellerSeeOrderDropDown"></div>
									<div style='z-index:99999'
										 class="w-1/2 absolute bg-white shadow border rounded mt-1"
										 v-if="openAllowSellerSeeOrderDropDown && filteredSellerSellerSeeOrderList.length > 0">
										<ul>
											<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredSellerSellerSeeOrderList.slice(0, 7)"
												:key="item.id" @click="addSellerAllowedToSeeOrder(item)"
											>{{ item.name }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row" v-if="openAllowSellerSeeOrderToggle === true">
									<div class="col-md-6 mb-1 mt-2"
										 v-for="(seller,key) in form.seller_allowed_to_see_buyer_orders"
										 :key="key">
										<div class="flex justify-between bg-white border rounded-md shadow-sm p-1">
											<div>{{ seller.name }}</div>
											<div>
                                                <span class="cursor-pointer" @click="removeSellerAllowedToSeeOrder(key)"
												>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
														 class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														 viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
														  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														  clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label
								class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
								<input data-cy='apply-send_order_notifications_to_admin'
									   type="checkbox"
									   id="send_order_notifications_to_admin"
									   v-model="form.allow_seller_send_employee_invite"
									   class="checkbox-height  h-4 w-4">
								<span class="ml-3"> {{ $t('allow_seller_send_employee_invite') }}<span
									class="help-tooltip cursor-pointer"
									v-bind:title="$t('allow_seller_send_employee_invite')"></span></span>
							</label>

						</div>
					</div>
					<div class="col-md-12 mt-5 mb-4">
                                    <span class="flex justify-center">
                                        <button data-cy='setting-btn' type="button" @click="updateSettings" :disabled="updating"
												:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
												:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
												class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                            <span v-if="updating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('updating') }}
                                            </span>
                                            <span v-else>{{ $t('update') }}</span>
                                        </button>
                                    </span>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex"
import debounce from "lodash.debounce"

export default {
	name: "customerSettings",
	data: function () {
		return {
			updating: false,
			loading: false,
			openAllowSellerSeeOrderToggle: false,
			openAllowSellerSeeOrderDropDown: false,
			searchShopName: '',
			filteredSellerSellerSeeOrderList: [],
			filteredSellerSellerPlaceOrderList: [],
			selectedSellerAccount: [],
			openAllowSellerPlaceOrderToggle: false,
			openAllowSellerPlaceOrderDropDown: false,
			searchSellerName: '',
			shopNumber: [],
			connectedShop: {
				suppliers: [],
				total_pages: 1
			},
			customerInfo: {},
			form: {
				allow_seller_send_employee_invite: false,
				allow_seller_place_order: false,
				allow_seller_to_see_your_orders: false,
				seller_allowed_to_place_orders: [],
				seller_allowed_to_see_buyer_orders: [],
			}
		}
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_CUSTOMER: 'customer/GET_CUSTOMER_NAME'
		})
	},
	methods: {
		updateSettings: function () {
			this.updating = true
			let seller_to_see_order = []
			let seller_to_place_order = []
			if (this.form.allow_seller_place_order === true) {
				this.form.seller_allowed_to_place_orders.map(item => {
					seller_to_place_order.push(item.id)
				})
			}
			if (this.form.allow_seller_to_see_your_orders === true) {
				this.form.seller_allowed_to_see_buyer_orders.map(item => {
					seller_to_see_order.push(item.id)
				})
			}

			let payload = {
				id: this.customerInfo.id,
				data: {
					seller_allowed_to_place_orders: seller_to_place_order,
					seller_allowed_to_see_buyer_orders: seller_to_see_order,
					allow_seller_place_order: this.form.allow_seller_place_order,
					allow_seller_to_see_your_orders: this.form.allow_seller_to_see_your_orders,
					allow_seller_send_employee_invite: this.form.allow_seller_send_employee_invite,
				}
			}
			this.$store.dispatch('customer/patchUserSettings', payload).then(resp => {
				this.updating = false
				this.$services.helpers.notification(this.$t('settingUpdated'), 'success', this)
			}).catch(err => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})
		},
		addSellerAllowedToSeeOrder: function (item) {
			let exist = this.form.seller_allowed_to_see_buyer_orders.filter(item_ => item.id === item_.id)
			if (exist.length <= 0) {
				this.form.seller_allowed_to_see_buyer_orders.push(item)
			}
			this.openAllowSellerSeeOrderDropDown = false

		},
		removeSellerAllowedToSeeOrder: function (key) {
			this.form.seller_allowed_to_see_buyer_orders.splice(key, 1)
		},
		addSellerAllowedToPlaceOrder: function (item) {
			let exist = this.form.seller_allowed_to_place_orders.filter(item_ => item.id === item_.id)
			if (exist.length <= 0) {
				this.form.seller_allowed_to_place_orders.push(item)
			}
			this.openAllowSellerPlaceOrderDropDown = false

		},
		removeSellerAllowedToPlaceOrder: function (key) {
			this.form.seller_allowed_to_place_orders.splice(key, 1)
		},
		getCompanyUser() {
			this.$store.dispatch('customer/getUserAccountSettings').then(resp => {
				this.form.allow_seller_place_order = resp.data.data.allow_seller_place_order
				this.form.allow_seller_to_see_your_orders = resp.data.data.allow_seller_to_see_your_orders
				this.form.allow_seller_send_employee_invite = resp.data.data.allow_seller_send_employee_invite
				this.form.seller_allowed_to_place_orders = resp.data.data.seller_allowed_to_place_orders
				this.form.seller_allowed_to_see_buyer_orders = resp.data.data.seller_allowed_to_see_buyer_orders

				this.customerInfo = resp.data.data
			})
		},
		getConnectedShopList() {
			this.loading = true
			let url = `?is_connected=True&no_paging=1`
			this.$store.dispatch('customer/getConnectedListShopList', {data: url})
				.then(res => {
					this.connectedShop.suppliers = res.data.suppliers
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					window.Bus.$emit('output-error', err)
				})
		},
		filterAllowSeller: debounce(function () {
			let url = `?is_connected=True&no_paging=1`
			if (this.searchShopName) {
				url += `?search=${this.searchShopName}`
			}
			this.$store.dispatch('customer/getConnectedListShopList', {data: url}).then(resp => {
				this.filteredSellerSellerSeeOrderList = resp.data.suppliers
			})
		}),
		filterAllowSellerPlaceOrder: debounce(function () {
			let url = `?is_connected=True&no_paging=1`
			if (this.searchSellerName) {
				url += `?search=${this.searchSellerName}`
			}
			this.$store.dispatch('customer/getConnectedListShopList', {data: url}).then(resp => {
				this.filteredSellerSellerPlaceOrderList = resp.data.suppliers
			})
		}, 300)
	},
	mounted() {
		this.getConnectedShopList()
		this.getCompanyUser()


	}
}
</script>

<style scoped>

</style>
